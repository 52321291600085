<template>
	<div class="detail-produk">
		<div class="container mt-5 mb-5">
			<div class="row g-3">
				<div class="col-lg-8 offset-lg-2">
					<skeleton-card-loading :isLoading="isLoadingQ" :lCard="1" :typeGrid="6"/>
					
					<div class="row mt-5">
						<div class="col-lg-12">
							<h3 class="mb-3" v-if="data_query">{{ data_query.title }}</h3>

							<img :src="data_query.featured_image" class="img-fluid mb-3" style="width:50%;">	

							<div v-if="data_query" v-html="data_query.content">
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'
export default {
	name: 'DetailProduk',
	components:{
		SkeletonCardLoading
	},
	data(){
		return{
			search: '',
			isLoadingQ: true,
			isLoadingProduk: true,
			isLoadingQN: true,
			data_query: {},
			next_query: {},
			postList: []
		}
	},
	created(){
		this.get_d(this.$route.params.id)
		this.getProduk()
	},
	methods:{
		goDetail(item){
			this.isLoadingQ = true
			this.isLoadingQN = true
			this.$router.push({ name: 'DetailProduk', params: { id: item.id, slug: item.slug } });
			this.get_d(item.id)
			this.generatenexq(item.id)
		},
		get_d(id){
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post/'+id).then((response) => {
				this.data_query = response.data
				this.isLoadingQ = false
			})
		},
		getProduk() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=10&type=product').then((response) => {
				this.postList = response.data.posts
				this.isLoadingProduk = false
				this.generatenexq(this.$route.params.id)
			})
		},
		generatenexq(id){
			this.isLoadingQN = true;
			const index = this.postList.findIndex(object => {
				return object.id === id;
			})
			if(index+1 < this.postList.length){
				this.next_query = this.postList[index+1];
			}else{
				this.next_query = this.postList[0];
			}
			this.isLoadingQN = false;
		}
	},
	computed: {
		data_produk() {
			return this.postList.filter(object => {
				return object.title.toLowerCase().includes(this.search.toLowerCase())
			})
		}
	}
}
</script>